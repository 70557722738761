import React, { useEffect, useState } from 'react'

import { useApp } from '@/core/contexts/app'

import { Row, Column } from '@/components/base/gridview'
import Button from '@/components/base/button/Button'

import query from '@/services/http/wap/query'

import { ContactFormResponseType } from './types'
import { classnames } from 'begonya/functions'

const ContactForm = () => {
  const app = useApp()
  const [formStatus, setFormStatus] =
    useState<ContactFormResponseType>('default')
  const [loadingState, setloadingState] = useState(false)
  const [formValues, setFormValues] = useState<any>({})
  const [formErrorValues, setFormErrorValues] = useState<any>({
    name: '',
    email: '',
    phone: '',
    address: '',
    topic: '',
    message: '',
  })

  useEffect(() => {
    formStatus && setloadingState(false)
  }, [formStatus])

  const validateForm = () => {
    const errors: any = {}

    if (!formValues.name) {
      errors.name = app.settings.translations['name.error']
    }

    if (!formValues.email) {
      errors.email = app.settings.translations['email.error']
    }

    if (!formValues.phone) {
      errors.phone = app.settings.translations['input.error']
    }

    if (!formValues.message) {
      errors.message = app.settings.translations['message.error']
    }

    setFormErrorValues(errors)
    return Object.values(errors).every((error) => error === '')
  }

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (!validateForm()) {
      return
    }

    setloadingState(true)

    const formDataArray = [
      {
        name: 'title',
        data: {
          refs: [],
          value: formValues.name,
        },
      },
      {
        name: 'adres',
        data: {
          refs: [],
          value: formValues.address,
        },
      },
      {
        name: 'konu',
        data: {
          refs: [],
          value: formValues.topic,
        },
      },
      {
        name: 'mesaj',
        data: {
          refs: [],
          value: formValues.message,
        },
      },
      {
        name: 'telefon',
        data: {
          refs: [],
          value: formValues.phone,
        },
      },
      {
        name: 'Email',
        data: {
          refs: [],
          value: formValues.email,
        },
      },
    ]

    const requestBody = {
      parameters: null,
      columns: formDataArray,
    }

    query
      .runForm<{ id: string }>(
        app.environment,
        {
          name: 'system-create-contact-us-data',
          language: app.language,
        },
        requestBody
      )
      .then((response) => {
        if (response.data.id) {
          setFormStatus('success')
          setTimeout(() => {
            setFormStatus('default')
            setFormValues({
              name: '',
              email: '',
              phone: '',
              address: '',
              topic: '',
              message: '',
            })
          }, 2000)
        }
        setloadingState(false)
      })

      .catch(() => {
        setFormStatus('error')
        setTimeout(() => setFormStatus('default'), 2000)
        setloadingState(false)
      })
  }

  return (
    <React.Fragment>
      <form onSubmit={onSubmit} className="contact-form">
        <Row>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <span>{app.settings.translations['name']}</span>
            <div>
              <label
                className={classnames(
                  'error-message',
                  formErrorValues.name && 'error-message--active'
                )}
              >
                {formErrorValues.name}
              </label>
              <input
                type="text"
                name="name"
                placeholder={app.settings.translations['name']}
                onChange={(e) => {
                  setFormValues({ ...formValues, name: e.target.value })
                  setFormErrorValues({ ...formErrorValues, name: '' })
                }}
              />
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <span>{app.settings.translations['email']}</span>
            <div>
              <label
                className={classnames(
                  'error-message',
                  formErrorValues.email && 'error-message--active'
                )}
              >
                {formErrorValues.email}
              </label>
              <input
                type="email"
                name="email"
                placeholder={app.settings.translations['email']}
                onChange={(e) => {
                  setFormValues({ ...formValues, email: e.target.value })
                  setFormErrorValues({ ...formErrorValues, email: '' })
                }}
              />
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <span>{app.settings.translations['phone']}</span>
            <div>
              <label
                className={classnames(
                  'error-message',
                  formErrorValues.phone && 'error-message--active'
                )}
              >
                {formErrorValues.phone}
              </label>
              <input
                type="phone"
                name="phone"
                placeholder={app.settings.translations['phone']}
                onChange={(e) => {
                  setFormValues({ ...formValues, phone: e.target.value })
                  setFormErrorValues({ ...formErrorValues, phone: '' })
                }}
              />
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 6 }}
            xl={{ size: 6 }}
          >
            <span>{app.settings.translations['address']}</span>
            <div>
              <label
                className={classnames(
                  'error-message',
                  formErrorValues.address && 'error-message--active'
                )}
              >
                {formErrorValues.address}
              </label>
              <input
                type="text"
                name="message"
                placeholder={app.settings.translations['address']}
                onChange={(e) => {
                  setFormValues({ ...formValues, address: e.target.value })
                  setFormErrorValues({ ...formErrorValues, address: '' })
                }}
              />
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <span>{app.settings.translations['topic']}</span>
            <div>
              <label
                className={classnames(
                  'error-message',
                  formErrorValues.topic && 'error-message--active'
                )}
              >
                {formErrorValues.topic}
              </label>
              <input
                type="text"
                name="topic"
                placeholder={app.settings.translations['topic']}
                onChange={(e) => {
                  setFormValues({ ...formValues, topic: e.target.value })
                  setFormErrorValues({ ...formErrorValues, topic: '' })
                }}
              />
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <span>{app.settings.translations['message']}</span>
            <div>
              <label
                className={classnames(
                  'error-message',
                  formErrorValues.message && 'error-message--active'
                )}
              >
                {formErrorValues.message}
              </label>
              <textarea
                name="message"
                placeholder={app.settings.translations['message']}
                onChange={(e) => {
                  setFormValues({
                    ...formValues,
                    message: e.target.value,
                  })
                  setFormErrorValues({ ...formErrorValues, message: '' })
                }}
              />
            </div>
          </Column>
          <Column
            xs={{ size: 12 }}
            sm={{ size: 12 }}
            md={{ size: 12 }}
            lg={{ size: 12 }}
            xl={{ size: 12 }}
          >
            <Button className="submit" type="submit" shape="pill">
              {loadingState ? (
                <div className="spinner">
                  {app.settings.translations['sending']}
                </div>
              ) : (
                app.settings.translations['send']
              )}
            </Button>
          </Column>
        </Row>
      </form>
      <div
        className={classnames(
          'alert-message',
          formStatus === 'success'
            ? 'alert-message--success alert-message--active'
            : '',
          formStatus === 'error'
            ? 'alert-message--error alert-message--active'
            : ''
        )}
      >
        {(() => {
          switch (formStatus) {
            case 'success':
              return app.settings.translations['success.message']
            case 'error':
              return app.settings.translations['error.message']
            default:
              return null
          }
        })()}
      </div>
    </React.Fragment>
  )
}

export default ContactForm
